import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Lang = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
    document.cookie = `language=${lang}; path=/`;
  };

  useEffect(() => {
    const savedLanguage =
      localStorage.getItem('language') ||
      document?.cookie
        ?.split('; ')
        .find((row) => row.startsWith('language='))
        ?.split('=')[1];
    if (savedLanguage && savedLanguage !== currentLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [currentLanguage, i18n]);

  return (
    <div className="mb-4">
      <hr />
      <span className="w-[86%] text-center">
        <a
          onClick={() => changeLanguage('it')}
          style={{
            cursor: 'pointer',
            fontWeight: currentLanguage === 'it' ? 'bold' : 'normal',
          }}>
          IT
        </a>{' '}
        |{' '}
        <a
          onClick={() => changeLanguage('en')}
          style={{
            cursor: 'pointer',
            fontWeight: currentLanguage === 'en' ? 'bold' : 'normal',
          }}>
          EN
        </a>
      </span>
    </div>
  );
};

export default Lang;
